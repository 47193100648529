<template>
    <v-container
        v-resize="onResize"
        fluid
        :style="`display:flex;background-color: #FAFAFA; height: ${height}px`"
    >
        <v-row
            no-gutters
            style="display:flex;justify-content:center;align-items:center"
        >
            <v-spacer />
            <v-col cols="6">
                <v-img
                    class="mb-2"
                    style="margin-left:auto; margin-right:auto"
                    contain
                    src="@/assets/PROJECT_AGENDA.png"
                    max-height="320px"
                    max-width="320px"
                />
                <div style="display: flex; justify-content: center">
                    <h1>Temporary Offline</h1>
                </div>
            </v-col>
            <v-spacer />
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'TemporaryOffline',
}
</script>
